<div>
    <h3>👍 Lo mejor</h3>
    <ul>
        <li *ngFor="let pro of analysis?.pros?.split(';')">{{ pro }}</li>
    </ul>
</div>
<div>
    <h3>👎 Lo peor</h3>
    <ul>
        <li *ngFor="let con of analysis?.cons?.split(';')">{{ con }}</li>
    </ul>
</div>