<section>
    <div>
        <div *ngFor="let post of posts">
            <article [style.height.px]="cardHeight || 200">
                <img [src]="post?.image" [alt]="post.slug" loading="lazy">
                <div [routerLink]="['/', parent?.slug || post.parent?.slug, post.slug]">
                    <a [routerLink]="['/', parent?.slug || post.parent?.slug, post?.slug]">{{post?.title.split('.')[0]}}</a>
                </div>
            </article>
        </div>
    </div>
</section>