import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-cookies-banner',
  templateUrl: './cookies-banner.component.html',
  styleUrls: ['./cookies-banner.component.scss']
})
export class CookiesBannerComponent { 
  
  @Output() accepted = new EventEmitter();

  public accept() {
    this.accepted.emit();
  }

}
