<ng-container *ngFor="let paragraph of paragraphs">
    <h3 *ngIf="paragraph?.htmlTag.content == 'title'" [id]="paragraph.content | encodeuri" [innerHtml]="'✳️ ' + paragraph.content"></h3>
    <h4 *ngIf="paragraph?.htmlTag.content == 'subtitle'" [id]="paragraph.content | encodeuri" [innerHtml]="'✔️ ' + paragraph.content"></h4>
    <p *ngIf="paragraph?.htmlTag.content == 'paragraph'" [id]="paragraph.content | encodeuri" [innerHtml]="paragraph.content"></p>
    <strong *ngIf="paragraph?.htmlTag.content == 'quote'" [id]="paragraph.content | encodeuri" [innerHtml]="paragraph.content"></strong>
    <span *ngIf="paragraph?.htmlTag.content == 'summary'" [id]="paragraph.content | encodeuri" [innerHtml]="paragraph.content"></span>
    <em *ngIf="paragraph?.htmlTag.content == 'internal-link'" [innerHtml]="paragraph.content"></em>
    <img *ngIf="paragraph?.htmlTag.content == 'image'" [id]="paragraph.content | encodeuri" [src]="paragraph.content" [alt]="paragraph.content" loading="lazy">
    <div *ngIf="paragraph?.htmlTag.content == 'images'" [id]="paragraph.content | encodeuri" class="imgs">
        <img *ngFor="let image of paragraph.content.split(';')" [src]="image" [alt]="image" loading="lazy">
    </div>
    <ul *ngIf="paragraph?.htmlTag.content == 'wikiloc'" [id]="paragraph.content | encodeuri" class="wikiloc">
        <li *ngFor="let wikiloc of decode(paragraph.content)">
            <a [href]="wikiloc.url" target="_blank">{{wikiloc.name}}</a>
            <div>
                <span><span>Dificultad:</span>{{wikiloc.hard === 3 ? 'Difícil' : (wikiloc.hard === 2 ? 'Medio' : 'Fácil')}}</span>
                <span><span>Longitud:</span>{{wikiloc.km}}km</span>
                <span><span>Valoración</span><meter [value]="wikiloc.rate" min="0" max="5" [value]="wikiloc.rate" max="5"></meter></span>
            </div>
        </li>
    </ul>
    <ng-container *ngIf="paragraph?.htmlTag.content === 'video'">
        <div *ngIf="!showVideo" class="youtube-placeholder" (click)="loadVideo()">
            <img [src]="'https://i.ytimg.com/vi/' + paragraph.content.split(';')[0] + '/hqdefault.jpg'" [alt]="'https://i.ytimg.com/vi/' + paragraph.content.split(';')[0] + '/hqdefault.jpg'" [style.width.px]="paragraph.content.split(';')[1]" [style.height.px]="paragraph.content.split(';')[2]" loading="lazy">
            <div></div>
        </div>
        <iframe *ngIf="showVideo" [src]="ds.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + paragraph.content.split(';')[0])" [width]="paragraph.content.split(';')[1]" [height]="paragraph.content.split(';')[2]"
            [title]="'https://www.youtube.com/embed/' + paragraph.content.split(';')[0]" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ng-container>
    <div *ngIf="paragraph?.htmlTag.content === 'faq'" class="faq">
        <h4 [id]="paragraph.content.split(';')[0] | encodeuri" [innerHtml]="paragraph.content.split(';')[0]"></h4>
        <p [innerHtml]="paragraph.content.split(';')[1]"></p>
    </div>
    <div *ngIf="paragraph?.htmlTag.content === 'vip'" class="vip">
        <img [src]="paragraph.content.split(';')[1]" [alt]="paragraph.content.split(';')[1]">
        <p [innerHtml]="paragraph.content.split(';')[0]"></p>
    </div>
</ng-container>