<p>Indice</p>
<ul>
    <ng-container *ngFor="let title of structure; let i = index">
        <li>
            <a [routerLink]="routerLink" [fragment]="title.name | encodeuri" [title]="title.name">✳️ {{title.name}}</a>
            <ul>
                <li *ngFor="let subtitle of title.subtitles; let j = index">
                    <a [routerLink]="routerLink" [fragment]="subtitle.name | encodeuri" [title]="subtitle.name">✔️ {{subtitle.name}}</a>
                </li>
            </ul>
        </li>
    </ng-container>
</ul>