import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageComponent } from './blog/components/pages/page/page.component';
import { NotfoundComponent } from './blog/components/notfound/notfound.component';
import { RoutingResolver } from './blog/resolvers/routing-resolver/routing.resolver';

const routes: Routes = [
  { path: '', pathMatch: 'full', loadChildren: () => import('./blog/components/home2/home2.module').then(m => m.Home2Module) },
  { path: 'tienda', loadChildren: () => import('./store/store.module').then(m => m.StoreModule) },
  { path: '404', component: NotfoundComponent, pathMatch: 'full', loadChildren: () => import('./blog/components/notfound/notfound.module').then(m => m.NotfoundModule) },
  { path: 'privacy', pathMatch: 'full', loadChildren: () => import('./loyalty-privacy/loyalty-privacy.module').then(m => m.LoyaltyPrivacyModule) },
  { path: 'cookies', pathMatch: 'full', loadChildren: () => import('./loyalty-cookies/loyalty-cookies.module').then(m => m.LoyaltyCookiesModule) },
  { path: 'legal', pathMatch: 'full', loadChildren: () => import('./legal/legal.module').then(m => m.LegalModule) },
  { path: 'terms-conditions', pathMatch: 'full', loadChildren: () => import('./terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule) },
  { path: '**', component: PageComponent, resolve: { post: RoutingResolver}, loadChildren: () => import('./blog/components/pages/page.module').then(m => m.PageModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
