<div>
    <app-rrss *ngIf="post" [post]="post"></app-rrss>
    <h1>{{post?.title.split('.')[0]}}<span>{{post?.title.split('.')[1]}}</span></h1>
</div>
<div>
    <div>
        <app-paragraphs [paragraphs]="post?.paragraphs?.slice(0, 2)"></app-paragraphs>
        <app-grid [posts]="post?.children" [parent]="post"></app-grid>
        <app-paragraphs [paragraphs]="post?.paragraphs?.slice(2)"></app-paragraphs>
    </div>
    <aside>
    </aside>
</div>